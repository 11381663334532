import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { background } from 'styles/colors';
import { Caption, Svg } from 'components';
import { AppState } from 'state/types';
import { IconsType } from 'components/images/DynamicImage';
import { QuestionOption, QuizAnswer } from 'types/quiz';

import { Regular } from '../text/Regular';

interface Props {
  label: string;
  description?: string;
  custom: QuestionOption['custom'] & {
    iconSize?: string;
    icon: IconsType;
  };
  onClick: () => void;
  selected?: boolean;
}

const checkIfDisabled = (condition?: string, answers?: QuizAnswer) => {
  if (typeof condition === 'string') {
    const answersStr = JSON.stringify(answers);
    const re = new RegExp(condition + '\\b');
    return re.test(answersStr);
  }
  return condition;
};

export const OptionCard: React.FC<Props> = ({
  label,
  description,
  custom,
  onClick,
  selected,
}) => {
  const answers = useSelector((s: AppState) => s.user.quiz_answers);
  const { disabled, horizontal, iconSize, icon } = custom;
  const isDisabled = checkIfDisabled(disabled, answers);

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <OptionBox
      onClick={handleClick}
      isHorizontal={!!horizontal}
      className={`${selected && 'selected'} ${isDisabled && 'disabled'}`}
      isBig={!!description}
    >
      <AlignCenter>
        {icon && (
          <Svg
            height={iconSize ? iconSize : '36'}
            width={iconSize ? iconSize : '36'}
            viewBox="0 0 48 48"
            src={`quiz/${icon}`}
          />
        )}
        <Caption margin={description ? '0 0 0.5rem 0' : '0'}>{label}</Caption>
        {description && <Regular>{description}</Regular>}
      </AlignCenter>
    </OptionBox>
  );
};

interface Styles {
  isBig: boolean;
  isHorizontal: boolean;
}

const OptionBox = styled.div<Styles>`
  position: relative;
  display: inline-block;
  height: ${props => (props.isBig ? '180px' : '140px')};
  width: ${props => (props.isBig ? '180px' : '140px')};
  margin: 0.5rem;
  background: ${background};
  border-radius: 4px;
  transition: background 0.2s;
  vertical-align: top;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 767px) {
    :hover:not(.disabled):not(.selected) {
      background: #d6f0e0;
    }
  }

  &.selected {
    background: #71cb93;
  }

  &.disabled {
    background: #7e7e7e;
    opacity: 0.8;
  }

  ${({ isHorizontal }) =>
    isHorizontal &&
    `
    display: block;
    width: auto;
    height: 21px;
    max-width: 300px;
    padding: 1rem;
    margin: 0 auto 1rem auto;
  `}
`;

const AlignCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 0 10px;
  transform: translateY(-50%);
`;
