import styled, { Keyframes } from 'styled-components';
import {
  fadeInAnimation,
  fadeInAnimationWithOutOpacity,
  fadeOutAnimation,
} from 'utils/animation';

type AnimationEffect = 'fadeOut' | 'fadeIn' | 'fadeInWithoutOpacity';

const animations: { [key in AnimationEffect]: Keyframes } = {
  fadeOut: fadeOutAnimation,
  fadeInWithoutOpacity: fadeInAnimationWithOutOpacity,
  fadeIn: fadeInAnimation,
};

export const FadeInAndOut = styled.div<{
  isVisible: boolean;
  duration?: string;
  fadeInWithOpacity?: boolean;
  delay?: string;
  effect?: AnimationEffect;
}>`
  width: 100%;
  opacity: 0;

  animation: ${({ effect, isVisible, fadeInWithOpacity = true }) =>
      animations[
        effect || isVisible
          ? fadeInWithOpacity
            ? 'fadeIn'
            : 'fadeInWithoutOpacity'
          : 'fadeOut'
      ]}
    forwards;
  animation-duration: ${({ duration }) => duration || '2s'};
  animation-delay: ${({ delay }) => delay || '0s'};
`;
