import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { mobile } from '../../styles/breakpoints';

const Button = styled.button`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #111111;
  width: 10.56rem;
  height: 3.5rem;
  background-color: #ffffff;
  border: 0.13rem solid #1b2849;
  border-radius: 0.75rem;
  cursor: pointer;

  &:hover {
    font-weight: 600;
    background-color: #4f7bea;
    color: #ffffff;
    border: none;
  }

  @media ${mobile} {
    display: none;
  }
`;

export const HeaderButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement>
> = props => <Button {...props} />;
