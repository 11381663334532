import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';

interface DisclaimerProps {
  paragraphs: string[];
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  @media ${tablet} {
    text-align: center;
  }
`;

const Disclaimer: FC<DisclaimerProps> = ({ paragraphs, ...props }) => {
  const { isTablet } = useQuery();
  return (
    <Container {...props}>
      {paragraphs.map((paragraph, index) => (
        <Text
          key={index}
          textAlign={isTablet ? 'center' : 'left'}
          type={isTablet ? 'bodyS' : 'bodyM'}
          color="dark60"
        >
          {paragraph}
        </Text>
      ))}
    </Container>
  );
};

export default Disclaimer;
