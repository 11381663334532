import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ArrowLeftIcon from 'assets/icons/arrow-left.svg';
import ButtonContainer from 'components/ButtonContainer';

interface BackButtonProps {
  onClick: () => void;
}

const Container = styled(ButtonContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowLeftIconStyled = styled(ArrowLeftIcon)`
  width: 1.5rem;
`;

const IconContainer = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  justify-content: center;
  align-items: center;
  margin-right: 0.62rem;
`;

const BackButton: FC<BackButtonProps> = ({ onClick }) => (
  <Container onClick={onClick}>
    <IconContainer>
      <ArrowLeftIconStyled height={16} width={16} />
    </IconContainer>
  </Container>
);

export default React.memo(BackButton);
