import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Seo, Text } from 'components';
import { laptop, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import Disclaimer from './Disclaimer';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import theme from 'utils/theme';
import LandingLayout from './LandingLayout';
import { useScrollToTop } from 'utils/hooks';
import { useLocation } from '@reach/router';
import Header from 'components/reviews/Header';
import LCFooter from 'components/LCFooter';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light0};
`;

const Wrapper = styled.section<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 3rem 1rem 78px;
  width: 100%;
  min-height: ${({ isFullHeight }) =>
    isFullHeight ? 'calc(100vh - 51px)' : 'calc(100vh - 233px)'};
  @media ${tablet} {
    flex-direction: column;
    text-align: center;
    padding-bottom: 0.5rem;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  margin-top: 4rem;
  max-width: 48rem;

  @media ${tablet} {
    margin-top: 0.5rem;
  }
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;

  @media ${tablet} {
    font-size: 1.75rem;
  }

  * {
    font-size: inherit;
    font-family: inherit;
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;
  max-width: 48rem;
  text-align: center;
  text-align-last: center;

  @media ${tablet} {
    padding-bottom: 2.25rem;
    display: flex;
    justify-content: center;
  }
`;

const CheckmarkListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    align-items: center;
  }
`;

const CheckmarkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.3125rem;
`;

const CheckTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.dark80};
  margin-left: 0.3125rem;
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media ${smMobile} {
    grid-template-columns: repeat(2, 8rem); /* Two columns */
  }
`;

const GenderButton = styled(PrimaryButton).attrs({})<{
  marginRight?: boolean;
  isSingleButton?: boolean;
}>`
  padding: 0 1rem;
  width: 100%;
  /* width: 160px; */
  /* width: 100%; */
  /* height: 3.5rem; */
  /* max-width: 15rem; */
  /* margin-right: 1rem; */
  /* flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'}; */
  grid-column: span 1; /* Each item spans one column */
  width: 164px;
  @media ${tablet} {
    width: 100%;
    height: 3rem;
    /* flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'}; */
  }
  @media ${smMobile} {
    /* width: 8rem; */
  }
`;

const GenderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 48rem;
`;

const Label = styled(Text)<{ descriptionIsMaxWidth?: boolean }>`
  font-weight: normal;
  font-size: 1.25rem;
  * {
    font-size: inherit;
    font-family: inherit;
  }
  line-height: 140%; /* 28px */
  letter-spacing: -0.32px;
  @media ${tablet} {
    font-size: 1rem;
    max-width: 48rem;
    text-align: left;
    text-align-last: left;
    width: 100%;
  }
`;

const LabelButtons = styled(Label)`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.016em;
  text-align: left;
  margin-bottom: 12px;
`;

const OtherGenderButton = styled(GenderButton)`
  /* flex-basis: calc(33% - 1rem); */
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : 'transparent'};
  grid-column: span 2; /* Each item spans one column */
  grid-template-rows: auto auto auto;
  margin-top: -1rem;
  @media ${tablet} {
    /* flex-basis: calc(50% - 1rem); */
    /* margin-top: 0.5rem; */
  }
`;

const MdpiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Mdpi = styled.div`
  width: 19rem;
  margin-top: 2rem;
  align-self: center;
  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;

const LeftContainer = styled.main`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  @media ${tablet} {
    margin-right: 0;
  }
`;
const RightContainer = styled.div`
  flex: 1;
`;

const MainImage = styled(DynamicImage)`
  max-width: 26rem;
  @media ${tablet} {
    max-width: 240px;
  }
`;

const OtherGenderLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.dark100};
  text-decoration: underline;
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isTablet } = useQuery();
  const location = useLocation();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-mw';
  const isAddsFlow = location.pathname.includes('start') || quiz !== 'main-mw';

  const renderGenderButtons = (options: any[]) => (
    <GenderButtonContainer>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </GenderButtonContainer>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          color={option?.color}
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <OtherGenderLabel color="secondary" type="body500">
              {option?.label}
            </OtherGenderLabel>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  const renderCheckmarks = (title: string, index: number) => (
    <CheckmarkItem key={index}>
      <CircleTick
        fill={theme.colors.green}
        width={isTablet ? 16 : 24}
        height={isTablet ? 16 : 24}
      />
      <CheckTitle type={isTablet ? 'body' : 'h3400'}>{title}</CheckTitle>
    </CheckmarkItem>
  );

  const getTitleFont = () => {
    if (isTablet) {
      return question?.titleFontMobile ? question.titleFontMobile : 'h125Rem';
    }
    return question?.titleFont ? question.titleFont : 'h125Rem';
  };

  useScrollToTop(0);
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <Container>
      <Seo />
      <Header isMobileApp={isAddsFlow} noBorder={isAddsFlow} />
      <Wrapper isFullHeight={isAddsFlow}>
        <LeftContainer>
          <MainImage src={question.desktopImg} alt={question.alt} />
          <TitleContainer>
            <Title
              // type={getTitleFont()}
              type="h1"
              textAlign={isTablet ? 'center' : 'left'}
              dangerouslySetInnerHTML={{ __html: question?.title || '' }}
            />
          </TitleContainer>
          {!!question?.description && (
            <DescriptionContainer>
              <Label
                type={isTablet ? 'body' : 'h3400'}
                color="dark80"
                textAlign={isTablet ? 'left' : 'left'}
                dangerouslySetInnerHTML={{
                  __html: question?.description || '',
                }}
                descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
              />
            </DescriptionContainer>
          )}
          {!!question?.checkmarkList && (
            <CheckmarkListContainer>
              {question.checkmarkList.map(renderCheckmarks)}
            </CheckmarkListContainer>
          )}
          <LabelButtons>{question.buttonLable}</LabelButtons>
          {renderGenderOptions()}
          {isTablet && !!question?.mdpiImage?.desktopImg && (
            <MdpiContainer>
              <Mdpi>
                <DynamicImage
                  src={question.mdpiImage.desktopImg}
                  alt={question.mdpiImage.alt}
                />
              </Mdpi>
            </MdpiContainer>
          )}
          {/* {!isTablet && (
          <Disclaimer paragraphs={question?.disclaimerParagraphs ?? []} />
        )} */}
        </LeftContainer>
      </Wrapper>
      {!isAddsFlow ? (
        <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
      ) : (
        <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
      )}
    </Container>
  );
};

export default Landing;

const DynamicImageStyled = styled(DynamicImage)`
  width: 100%;
  min-width: 523px;
  height: 451px;
  @media ${laptop} {
    min-width: auto;
  }
`;
const DynamicMobileImageStyled = styled(DynamicImage)`
  width: auto;
  height: 250px;
`;
