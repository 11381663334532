import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

import Header from 'components/Header';
import { Question } from 'types/quiz';
import { Text } from 'components';
import { laptop, loptopWidth, tablet, useQuery } from 'styles/breakpoints';
import Overlay from './Overlay';
import { useRouter } from 'apis/history';
import { Color } from 'utils/theme';
import LCFooter from 'components/LCFooter';

interface LandingLayoutProps {
  question: Question;
  navbarColor?: Color;
  disclaimer?: string | ReactNode;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.beige};
  /* background: linear-gradient(180deg, #faf6f5 0.05%, #e4dfed 100%); */
  background-color: #fff;
  background-size: cover;
  margin: auto;
`;

const NavRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4.5rem;

  @media ${tablet} {
    grid-template-columns: auto;
  }
`;

const NavRightLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 3rem;
  align-items: center;

  @media ${tablet} {
    display: none;
  }
`;

const NavRightStoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.5rem;
  align-items: center;
  .active-menu {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    backdrop-filter: blur(24px);
  }
  .menu-trigger {
    position: absolute;
    top: 10px;
    width: 48px;
    height: 48px;
    padding: 17px 16px;
    right: 4px;
    z-index: 100;
    cursor: pointer;
    display: block;

    span {
      display: block;
      margin-bottom: 4px;
      transition: all 0.2s ease;
      height: 2px;
      border-radius: 5px;
      background: #1c1c28;
      &:nth-child(1) {
        width: 50%;
        margin-left: 50%;
      }
      &:nth-child(3) {
        width: 75%;
        margin-left: auto;
      }
      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(-45deg);
        margin: 7px 0 -8px;
        width: 100%;
        border-radius: 0;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(45deg);
        border-radius: 0;
        width: 100%;
      }
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${loptopWidth};
  width: 100%;
  // height: 100%;
  padding: 3rem 1rem;
  // margin: auto;
  gap: 4rem;
  @media ${laptop} {
    gap: 2rem;
    max-width: 100%;
  }
  @media ${tablet} {
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
`;
const Disclaimer = styled.div`
  width: 100%;
`;

const LandingLayout: FC<LandingLayoutProps> = ({
  question,
  navbarColor,
  children,
  disclaimer,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const { isTablet } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-mw';

  const { goToContact, goToCases, goToStory } = useRouter();

  const renderRightComponent = () => (
    <NavRightContainer>
      <NavRightLinksContainer>
        <StyledContainer onClick={() => goToCases()}>
          <Text type="body500">Use cases</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToStory()}>
          <Text type="body500">Our Story</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToContact()}>
          <Text type="body500">Contact Us</Text>
        </StyledContainer>
        {/* {question?.navLinks?.map((navLink, index) => (
          <a key={index} href={navLink?.url}>
            <Text type="body500">{navLink?.title}</Text>
          </a>
        ))} */}
      </NavRightLinksContainer>
      <NavRightStoresContainer>
        {isTablet && (
          <div
            className={`menu-trigger`}
            onClick={() => setShow(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </div>
        )}
      </NavRightStoresContainer>
    </NavRightContainer>
  );

  return (
    <>
      <MainContainer className={show ? 'active-menu' : ''}>
        {show && (
          <Overlay
            navLinks={question?.navLinks ?? []}
            socialLinks={question?.socialLinks ?? []}
          />
        )}
        <Header
          logoVariant="center"
          sticky={false}
          // RightComponent={renderRightComponent()}
          color={navbarColor}
        />
        <ContentContainer>
          {children}
          {quiz === 'main-mw'
            ? null
            : disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
          {/* {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>} */}
        </ContentContainer>
        {quiz === 'main-mw' ? <LCFooter disclaimerText={[]} /> : null}
      </MainContainer>
    </>
  );
};

export default LandingLayout;
