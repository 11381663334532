import React, { FC } from 'react';
import styled from 'styled-components';

import Button from './Button';
import { surface } from 'styles/colors';

interface BrandHeaderProps {
  withButton?: boolean;
  onGetStarted?: () => void;
}

const Container = styled.div`
  height: 3.5rem;
  box-shadow: 0 0.0625rem 0.1875rem rgba(1, 12, 65, 0.2);
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${surface};
  z-index: 1;
`;

const ContentContainer = styled.div<{ withButton: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ withButton }) =>
    withButton ? 'space-between' : 'center'};
  max-width: 31.25rem;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ButtonStyled = styled(Button)`
  max-height: 2.1875rem;
`;

const BrandHeader: FC<BrandHeaderProps> = ({
  withButton,
  onGetStarted,
  ...props
}) => (
  <Container {...props}>
    <ContentContainer withButton={!!withButton}>
      {!!withButton && (
        <ButtonStyled fullWidth={false} onClick={onGetStarted}>
          Get Started
        </ButtonStyled>
      )}
    </ContentContainer>
  </Container>
);

export default BrandHeader;
