import React from 'react';
import styled from 'styled-components';

import { background, white } from '../../styles/colors';
import { Container } from '..';
import BrandHeader from '../../pages/start/results/components/BrandHeader';

interface IBackgroundWrapper {
  children: React.ReactNode;
}

export const BackgroundWrapper: React.FC<IBackgroundWrapper> = ({
  children,
}) => (
  <>
    <BrandHeaderStyled />

    <Background>
      <Container>
        <ModalView>{children}</ModalView>
      </Container>
    </Background>
  </>
);

const Background = styled.div`
  min-height: 100vh;
  background-color: ${background};
  padding: calc(56px + 24px) 16px 24px 16px;
  display: flex;
`;

const ModalView = styled.div`
  min-height: 520px;
  padding: 0 4%;
  text-align: center;
  background: ${white};
  border-radius: 6px;
  overflow: hidden;
`;

const BrandHeaderStyled = styled(BrandHeader)`
  background-color: ${background};
`;
