import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import styled from 'styled-components';

interface ProgressCountProps {
  total: number;
  count: number;
}

const TextStyled = styled(Text)`
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
`;

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => (
  <TextStyled type="body" color="dark80">
    {count}/{total}
  </TextStyled>
);

export default ProgressCount;
