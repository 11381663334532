import React, { FC, useCallback, useEffect } from 'react';
import RModal, { Props, Styles } from 'react-modal';
import { Close } from '@material-ui/icons';
import styled from 'styled-components';
import Text from 'components/Text';
import { mobile } from 'styles/breakpoints';

interface ModalProps {
  width: string;
}

const CloseIconContainerOld = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.light60};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  cursor: pointer;
`;

const CloseIconOld = styled(Close)`
  color: ${({ theme }) => theme.colors.dark100};
`;

const CloseIconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.dark100};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  position: sticky;
  top: 0px;
  background: rgb(255, 255, 255);
  z-index: 9999;
  align-items: center;
  @media ${mobile} {
    padding: 1rem 1rem 1.5rem;
  }
`;

const StyledText = styled(Text)`
  @media ${mobile} {
    max-width: 13.75rem;
  }
`;

export const Modal: FC<ModalProps & Props> = ({
  width,
  style,
  children,
  onRequestClose,
  ...props
}) => {
  const customStyle: Styles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 5,
      ...style?.overlay,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: '1.5rem',
      borderRadius: '0.75rem',
      border: '1px solid #a7a7a7',
      width,
      maxWidth: 'calc(100vw - 1rem)',
      maxHeight: 'calc(100vh - (100vh * 0.2))',
      ...style?.content,
    },
  };

  const handleAfterOpen = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const handleAfterClose = useCallback(() => {
    document.body.style.overflow = '';
  }, []);

  return (
    <RModal
      ariaHideApp={false}
      style={customStyle}
      onRequestClose={onRequestClose}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      {...props}
    >
      {!!onRequestClose && (
        <CloseIconContainerOld onClick={onRequestClose}>
          <CloseIconOld fontSize="small" />
        </CloseIconContainerOld>
      )}

      {children}
    </RModal>
  );
};
