import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { mobile, smLaptop, tablet } from 'styles/breakpoints';

interface HeaderProps {
  ButtonComponent?: ReactElement;
}

const Container = styled.div`
  min-height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${smLaptop} {
    padding: 0 1rem;
  }

  @media ${tablet} {
    padding: 0 3rem;
  }

  @media ${mobile} {
    min-height: 4.13rem;
    padding: 0 1rem;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  max-width: 70.88rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${mobile} {
    justify-content: center;
  }
`;

export const Header: FC<HeaderProps> = ({ ButtonComponent, ...props }) => (
  <Container {...props}>
    <ContentContainer>{!!ButtonComponent && ButtonComponent}</ContentContainer>
  </Container>
);
