import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { tablet, useQuery } from 'styles/breakpoints';

interface HeadlineProps {
  bodyText: string;
  bgColor?: string;
  textColor?: string;
  align?: string;
  alignMobile?: string;
  fontWeight?: number;
}

const Container = styled.div<{ bgColor?: string }>`
  width: 100%;
  padding: 1.5rem 0;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.colors.secondary};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${tablet} {
    padding: 1rem 0;
  }
`;

const BodyText = styled(Text)<{ textColor?: string }>`
  color: ${props => (props.textColor ? props.textColor : 'dark100')};
  margin: 0 1rem;
  font-size: 20px;
  @media ${tablet} {
    font-size: 14px;
  }
`;

const Headline: FC<HeadlineProps> = ({
  bodyText,
  bgColor,
  textColor,
  align = 'center',
  alignMobile = 'center',
  fontWeight = 500,
}) => {
  const { isTablet } = useQuery();

  return (
    <Container bgColor={bgColor}>
      <BodyText
        type={'body'}
        textColor={textColor}
        textAlign={isTablet ? alignMobile : align}
        color="dark100"
      >
        {bodyText}
      </BodyText>
    </Container>
  );
};

export default React.memo(Headline);
