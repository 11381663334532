import { Seo } from 'components';
import React from 'react';
import Landing from './start/quiz/components/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import Landing2 from './start/quiz/components/Landing2';
import { QuestionType } from 'utils/constants/questionsTypes';
import { usePageView } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import Tracking from 'utils/tracking';
import { navigate } from 'gatsby';

const question = {
  type: 'landing',
  key: 'gender',
  title: 'A revolutionary way to stop overthinking & start living',
  description:
    'Take a quick quiz to get a personalized plan to stop overthinking, overcome negative thought patterns and live a worry-free life',
  label: 'Start by selecting your gender',
  bfBanner: {
    saleText: 'CHRISTMAS <span>SALE</span>',
    saveText: 'SAVE UP TO',
    percentageText: '50%',
    spanColor: '#E24B5B',
    bubbleColor: '#E24B5B',
    countdownColor: '#E24B5B',
    variant: 'xmas',
  },
  options: [
    {
      label: 'Male',
      value: 'male',
      color: 'male',
    },
    {
      label: 'Female',
      value: 'female',
      color: 'green',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
  desktopImg: 'funnel/ad5/quiz/landing_des.png',
  mobileImgTitle: 'Effect on Procrastination',
  mobileImg: 'funnel/ad5/quiz/landing_mob.png',
  mdpiImageUrl: 'funnel/ad5/quiz/mdpi_p.png',
  disclaimerParagraphs: ['© 2024 Mindway. All rights reserved.'],
  disclaimerParagraphsPR: [
    '© 2024 Mindway. All rights reserved.',
    'Disclaimer: Results may vary from person to person.',
  ],
  navLinks: [
    {
      title: 'Use Cases',
      url: 'https://brainway.app/cases',
    },
    {
      title: 'Our Story',
      url: 'https://brainway.app/story',
    },
    {
      title: 'Contact Us',
      url: 'https://brainway.app/contact',
    },
  ],
  socialLinks: [
    {
      svgId: 'f-tel',
      url: 'https://t.me/effectoapp',
    },
    {
      svgId: 'f-reddit',
      url: 'https://www.reddit.com/r/EffectoApp',
    },
    {
      svgId: 'f-tw',
      url: 'https://twitter.com/EffectoApp',
    },
    {
      svgId: 'f-tt',
      url: 'https://tiktok.com/@effectoapp',
    },
    {
      svgId: 'f-fb',
      url: 'https://www.facebook.com/people/Brainway/100094037230804',
    },
    {
      svgId: 'f-instagram',
      url: 'https://www.instagram.com/brainway.app',
    },
  ],
};

const Home: React.FC = React.memo(() => {
  // const dispatch = useDispatch();
  // const questions = useSelector((state: AppState) => state.funnel.questions);
  // const question = questions[0];
  // const { goToQuizStart } = useRouter();
  // const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  // const q = new URLSearchParams(location.search).get('q') ?? '1';
  // const { geolocation } = useSelector((s: AppState) => s.user);

  // const logEvent = (data: { category: string; label?: string }) => {
  //   Tracking.logEvent({
  //     event: `Quiz question answered - ${quiz}`,
  //     category: `${q} - ${data.category}`,
  //     label: data.label,
  //   });
  // };

  // usePageView({
  //   state: normalizeStates(
  //     geolocation?.iso_country || '',
  //     geolocation?.iso_state || '',
  //   ),
  //   country: geolocation?.iso_country?.toLowerCase(),
  //   city: encodeURIComponent(
  //     geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
  //   ),
  // });

  // const handleLandingOptionSelect = (answer: QuizAnswer) => {
  //   logEvent({
  //     category: `${question.label}${
  //       question.optionsLabel ? ` - ${question.optionsLabel}` : ''
  //     }`,
  //     label: String(answer.label),
  //   });
  //   delete answer.label;
  //   dispatch(updateQuizAnswers(answer));
  //   goToQuizStart();
  // };

  const handleLandingOptionSelect = (answer: any) => {
    if (typeof window !== 'undefined') {
      // We're in the browser environment

      // Extract URL parameters using window.location.search
      const searchParams = new URLSearchParams(window.location.search);
      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });

      console.log('URL Parameters on Button Click:', params); // Debug log the URL parameters
      console.log(answer);
      // Optionally, navigate to another page with the parameters
      sessionStorage.setItem('genderAnswer', answer.label.toLowerCase());
      const queryString = new URLSearchParams(params).toString();
      navigate(`/start/?${queryString}&q=2`);
    }
  };

  return (
    <>
      <Seo />
      {question?.type === QuestionType.Landing ? (
        <Landing
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      ) : (
        <Landing2
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      )}
    </>
  );
});

Home.displayName = 'Home';

export default Home;
