import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from '../../styles/breakpoints';
import { graphql, useStaticQuery } from 'gatsby';

interface Styles {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  alignCenter?: boolean;
  justifyCenter?: boolean;
  objectFit?:
    | 'inherit'
    | 'none'
    | 'fill'
    | 'unset'
    | 'cover'
    | 'initial'
    | '-moz-initial'
    | 'revert'
    | 'revert-layer'
    | 'contain'
    | 'scale-down'
    | undefined;
}

interface Props extends Styles {
  src: string;
  alt: string;
  mobile_src?: string;
  tablet_src?: string;
  imageHeightDesktop?: string;
}

const Img = styled(GatsbyImage)<Styles>`
  display: block;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  max-height: ${props => props.maxHeight || 'none'};
  max-width: ${props => props.maxWidth || 'none'};
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginTop || 0};
  margin-left: ${props => props.marginLeft || 0};
  margin-right: ${props => props.marginRight || 0};
`;

const Picture = styled.picture<{
  alignCenter: boolean;
  justifyCenter: boolean;
}>`
  ${({ alignCenter }) =>
    alignCenter ? 'display: flex; align-items: center;' : ''}
  ${({ justifyCenter }) =>
    justifyCenter ? 'display: flex; justify-content: center;' : ''}
`;

export const DynamicImage: React.FC<Props> = ({
  src,
  alt,
  mobile_src,
  tablet_src,
  alignCenter = false,
  justifyCenter = false,
  imageHeightDesktop,
  height,
  objectFit,
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { extension: { regex: "/jpeg|jpg|png|gif|svg/" } }
      ) {
        edges {
          node {
            extension
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
                quality: 100
              )
            }
          }
        }
      }
    }
  `);
  const { isTablet } = useQuery();

  const imageSrc = isTablet ? mobile_src : src;

  const gatsbyImage = data?.images?.edges.find(
    (image: any) =>
      image.node?.relativePath === imageSrc || image.node?.relativePath === src,
  );

  return !gatsbyImage ? null : (
    <Img
      image={getImage(gatsbyImage?.node) as IGatsbyImageData}
      objectFit={objectFit || 'contain'}
      alt={''}
      loading={'lazy'}
      maxHeight={height}
      {...rest}
    />
  );
};
