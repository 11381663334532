import React, { FC } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components';
import { smMobile } from 'styles/breakpoints';

const ButtonStyled = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  min-width: 15rem;
  width: 100%;
  max-width: 21.4375rem;
  height: 3rem;

  @media ${smMobile} {
    max-width: 18rem;
  }
`;

const Button: FC = ({ children = 'Start Now', ...props }) => (
  <ButtonStyled {...props}>{children}</ButtonStyled>
);

export default Button;
