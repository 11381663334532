import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { tablet, useQuery } from 'styles/breakpoints';

import Text from './Text';

import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImage } from './images/DynamicImage';
import StartNowButton from '../pages/start/results/components/StartNowButton';

interface ReviewsProps {
  onClick?: () => void;
  title: string;
  reviews: string[];
  starImgUrl?: string;
  starImgUrlAlt: string;
  startBtnTitle?: string;
}

const Container = styled.div`
  padding: 4.5rem 1rem;
  margin: auto;
  max-width: 1080px;
  gap: 3rem;
  @media ${tablet} {
    gap: 1.5rem;
    padding: 3rem 1rem;
  }
`;

const StarImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 16.826875rem;
  @media ${tablet} {
    width: 11rem;
  }
`;

const StyledButton = styled(StartNowButton)`
  margin: 2rem auto 0 auto;
`;

const SwiperStyled = styled(Swiper)`
  margin: 3rem 0 0 0;

  @media ${tablet} {
    margin: 2rem 0 0 0;
  }

  .swiper-wrapper {
    margin-bottom: 4.5rem;
    @media ${tablet} {
      margin-bottom: 3rem;
    }
  }

  .swiper-slide {
    img {
      width: 100%;
      height: auto;
      border-radius: 1rem;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: ${({ theme }) => theme.colors.light60};

    &-active {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

const Reviews: FC<ReviewsProps> = ({
  title,
  reviews,
  starImgUrl,
  starImgUrlAlt,
  startBtnTitle,
  onClick,
}) => {
  const { isTablet } = useQuery();
  return (
    <Container>
      {!!starImgUrl && (
        <StarImageContainer>
          <StyledDynamicImage src={starImgUrl} alt={starImgUrlAlt} />
        </StarImageContainer>
      )}
      <Text type={isTablet ? 'h2600' : 'h1'} textAlign="center">
        {title}
      </Text>

      <SwiperStyled
        modules={[Pagination]}
        pagination={{ clickable: true }}
        allowTouchMove
        effect="slide"
        slidesPerView={1}
        spaceBetween={24}
        breakpoints={{
          480: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <DynamicImage src={review} alt="Slide" />
          </SwiperSlide>
        ))}
      </SwiperStyled>
      {startBtnTitle && (
        <StyledButton onClick={onClick}>{startBtnTitle}</StyledButton>
      )}
    </Container>
  );
};

export default Reviews;
