import React, { FC } from 'react';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

interface PageContainerProps {
  with100vh?: boolean;
}

const Container = styled.div<{ height: number; with100vh?: boolean }>`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: ${({ with100vh }) => (with100vh ? '100vh' : 'auto')};
  background: #fff;
`;

export const PageContainer: FC<PageContainerProps> = props => {
  const height = use100vh();

  return <Container height={height || 0} {...props} />;
};
