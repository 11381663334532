import React from 'react';
import styled from 'styled-components';

import { black, white } from '../../styles/colors';

interface IDefaultButton {
  onClick: () => void;
  label: string;
  disabled: boolean;
  isLoading: boolean;
  type: 'button' | 'submit' | 'reset';
}
export const DefaultButton: React.FC<IDefaultButton> = ({
  onClick,
  label,
  disabled,
  type,
  isLoading,
}) => {
  const handleClick = () => {
    if (!disabled && !isLoading && onClick) {
      onClick();
    }
  };

  return (
    <Container
      type={type}
      onClick={handleClick}
      disabled={disabled || isLoading}
      className={(type === 'submit' && 'long') || undefined}
    >
      {isLoading ? 'Please wait...' : label}
    </Container>
  );
};

const Container = styled.button`
  padding: 1rem 2rem;
  font-size: 1rem;
  color: ${white};
  background: ${black};
  border: none;
  border-radius: 4px;
  transition: opacity 0.2s;
  cursor: pointer;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.long {
    width: 100%;
  }
`;
