import React from 'react';
import styled from 'styled-components';

import Tick from 'assets/icons/tick-circle-green-tick.svg';

const Checkbox = ({ checked, onChange, quizVariant, props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    {quizVariant ? (
      checked ? (
        <Tick />
      ) : (
        <StyledCheckboxQuiz checked={checked}>
          <IconQuiz checked={checked} viewBox="0 0 24 24" />
        </StyledCheckboxQuiz>
      )
    ) : (
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

export default Checkbox;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  margin-bottom: 1px;
`;

const IconQuiz = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: ${({ theme, checked }) => (checked ? theme.colors.newGreen : '#FFF')};
  height: ${({ checked }) => (checked ? '24px' : '0px')};
  width: ${({ checked }) => (checked ? '24px' : '0px')};
  display: block;
  margin: auto;
  // stroke-width: 4px;
  position: absolute; // <-- Add this line
  top: 50%; // <-- Add this line
  left: 50%; // <-- Add this line
  transform: translate(-50%, -50%)
    scale(${props => (props.checked ? '1' : '0')}) translate(2px, 4px);
  opacity: ${props => (props.checked ? '1' : '0')}; // <-- Adjust opacity
  stroke-width: 0.5px;
  transition: transform 0.2s, opacity 0.1s;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.orange : '#FFF'};
  border: 2px solid
    ${({ checked, theme }) => (checked ? theme.colors.orange : '#d6d6d6')};
  border-radius: 4px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledCheckboxQuiz = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${({ checked }) => (checked ? '#FFF' : '#FFF')};
  /* border: 01px solid ${({ checked, theme }) =>
    checked ? '#FFF' : '#d6d6d6'}; */
  border: 01px solid
    ${({ checked, theme }) => (checked ? '#3FA134' : '#d6d6d6')};
  border-radius: 12px;
  position: relative; // <-- Add this line
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;
