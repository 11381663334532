import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ProjectConfigResponse } from '@frontend/api-wrapper';
import axios from 'axios';
import { config as rootConfig } from 'config';

import { TempApiBase } from 'apis/api';
import { handleError } from 'utils/error';

import { setPaymentGateway } from './actions';
import { getLocalisedIsoCountry } from 'utils/localization';

export const fetchPaymentGateway =
  () => async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    const ApiBase = axios.create({
      baseURL: rootConfig.BASE_URL,
      params: {
        api_token: rootConfig.API_TOKEN,
        p_locale: getLocalisedIsoCountry().toLowerCase(),
      },
    });
    try {
      const response = await ApiBase.get<{ data: ProjectConfigResponse }>(
        '/project',
      );
      dispatch(setPaymentGateway(response.data.data.gateway || null));
    } catch (e) {
      handleError(e as Error);
    }
  };

export const fetchTempPaymentGateway =
  () => async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    try {
      const response = await TempApiBase.get<{ data: ProjectConfigResponse }>(
        '/project',
      );
      dispatch(setPaymentGateway(response.data.data.gateway || null));
    } catch (e) {
      handleError(e as Error);
    }
  };
