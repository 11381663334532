import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile } from 'styles/breakpoints';
import ChevronRight from 'assets/icons/chevron-right.svg';
import CheckBox from './CheckBox';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  showCheckIcon?: boolean;
  showChevronIcon?: boolean;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  hasImage?: boolean;
}>`
  display: flex;
  flex-direction: ${({ hasImage }) => (hasImage ? 'column' : 'row')};
  min-height: 3.5rem;
  /* border: ${({ active }) => (active ? 0.09 : 0.06)}rem solid
    ${({ theme, active }) => theme.colors[active ? 'darkPeach' : 'light0']}; */
  border: ${({ active }) => (active ? 0.09 : 0.09)}rem solid
    ${({ theme, active }) => (active ? theme.colors.green : '#F0F0F0')};

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows.card};
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1rem;

  background-color: ${({ theme, active }) => (active ? '#E1FCDA' : '#F6F4EE')};

  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};

  @media ${mobile} {
    min-height: 3rem;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
  max-width: 80px;
  max-height: 64px;

  img {
    max-height: 64px;
  }
`;

const ButtonText = styled(Text).attrs({
  type: 'bodyM',
})<{
  active?: boolean;
  hasImage?: boolean;
}>`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: ${({ hasImage }) => (hasImage ? 'center' : 'left')};
  /* color: ${({ active }) => (active ? '#FFFFFF' : '#000000')}; */

  flex: 1;
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  showCheckIcon,
  showChevronIcon,
  ...props
}) => (
  <Container
    onClick={onClick}
    active={active}
    maxSelected={maxSelected}
    as="button"
    hasImage={!!ImageComponent}
    {...props}
  >
    {ImageComponent && <ImageContainer>{ImageComponent}</ImageContainer>}
    <ButtonText active={active} hasImage={!!ImageComponent}>
      {' '}
      {children}
    </ButtonText>
    {showCheckIcon && <CheckBox checked={active} quizVariant />}
    {showChevronIcon && <ChevronRight fill={active ? '#FFFFFF' : '#000000'} />}
  </Container>
);

export default React.memo(QuizAnswerButton);
