import React, { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';

import { Caption, Regular } from '..';
import { greyDark } from '../../styles/colors';

type Item = {
  value: any;
  label: string;
};

interface RadioInputGroupProps {
  name: string;
  items: Item[];
  additionalItems?: Item[];
  title: string;
  selectedValue: any;
  required?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface RadioInputProps {
  name: string;
  value: any;
  label: string;
  selectedValue: any;
  required: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Container = styled.div`
  margin-bottom: 16px;
  text-align: left;
`;

const RadioInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

const RadioInputContainer = styled.div`
  display: flex;
  margin: 4px 0;
`;

const InputStyled = styled.input`
  margin-right: 8px;
  cursor: pointer;
`;

const More = styled.a`
  color: ${greyDark};
  margin-left: 26px;
  cursor: pointer;
`;

const RadioInput: FC<RadioInputProps> = ({
  name,
  value,
  label,
  selectedValue,
  required,
  onChange,
  ...props
}) => (
  <RadioInputContainer {...props}>
    <InputStyled
      type="radio"
      name={name}
      value={value}
      checked={value === selectedValue}
      required={required}
      onChange={onChange}
    />

    <Regular>{label}</Regular>
  </RadioInputContainer>
);

export const RadioInputGroup: FC<RadioInputGroupProps> = ({
  name,
  items,
  additionalItems,
  title,
  selectedValue,
  required,
  onChange,
  ...props
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleMoreClick = () => {
    setExpanded(true);
  };

  return (
    <Container {...props}>
      <Caption>{title}</Caption>

      <RadioInputGroupContainer>
        {items.map((item, i) => (
          <RadioInput
            key={i}
            name={name}
            selectedValue={selectedValue}
            required={!!required}
            onChange={onChange}
            {...item}
          />
        ))}

        {!!additionalItems && (
          <>
            {expanded ? (
              additionalItems.map((additionalItem, i) => (
                <RadioInput
                  key={i}
                  name={name}
                  selectedValue={selectedValue}
                  required={!!required}
                  onChange={onChange}
                  {...additionalItem}
                />
              ))
            ) : (
              <More onClick={handleMoreClick}>more...</More>
            )}
          </>
        )}
      </RadioInputGroupContainer>
    </Container>
  );
};
