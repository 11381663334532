import React from 'react';
import styled from 'styled-components';

import { Regular } from '..';
import { black } from '../../styles/colors';

interface IInput {
  className: string;
  label: string;
  max: number;
  maxLength: number;
  min: number;
  name: string;
  onChange: () => void;
  pattern: string;
  placeholder: string;
  type: string;
  value: string;
}

export const Input: React.FC<IInput> = ({
  placeholder,
  type,
  name,
  label,
  className,
  min,
  max,
  value,
  onChange,
  pattern,
  maxLength,
}) => (
  <InputComp className={className}>
    <Flex>
      <Field
        value={value}
        onChange={onChange}
        onInvalid={
          type === 'email'
            ? e => {
                const input = e.target as HTMLInputElement;
                input.setCustomValidity('Enter an email address');
              }
            : () => {}
        }
        onInput={
          type === 'email'
            ? e => {
                const input = e.target as HTMLInputElement;
                input.setCustomValidity('');
              }
            : () => {}
        }
        placeholder={placeholder}
        type={type}
        name={name}
        required={true}
        min={min}
        max={max}
        maxLength={maxLength}
        pattern={
          type === 'email'
            ? '[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
            : pattern
        }
      />
      {label && <Regular>{label}</Regular>}
    </Flex>
  </InputComp>
);

const InputComp = styled.div`
  padding: 1rem 1.5rem;
  margin: 0 auto 1rem auto;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #d0d0d0;

  &.inline__left {
    flex: 1;
    margin-right: 0.5rem;
  }

  &.inline__right {
    flex: 1;
    margin-left: 0.5rem;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const Field = styled.input`
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: ${black};
  border: none;
  outline: none;
`;
