import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { mobile, tablet } from 'styles/breakpoints';

export const Timer = () => {
  const [time, setTime] = useState('09:34');

  useEffect(() => {
    let time = 573;

    const timer = setInterval(() => {
      let minutes = parseInt(`${time / 60}`, 10);
      let seconds = parseInt(`${time % 60}`, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      setTime(`${minutes}:${seconds}`);

      if (time < 0) {
        time = 599;
        setTime('09:59');
      }

      time--;
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Banner>
      <Text>
        The offer expires in: <span>{time}</span>
      </Text>
    </Banner>
  );
};

const Banner = styled.div`
  padding: 1rem;
  margin: 0 auto 3rem auto;
  background-color: #06c2c2;
  max-width: 46.63rem;
  width: 100%;

  @media ${tablet} {
    max-width: 48rem;
  }

  @media ${mobile} {
    margin: 0 auto 1.5rem auto;
  }
`;

const Text = styled.h5`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #ffffff;
  text-align: center;

  span {
    text-decoration: underline;
  }

  @media ${mobile} {
    font-size: 1.13rem;
    line-height: 1.5rem;
  }
`;
