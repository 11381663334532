import React, { FC } from 'react';
import {
  TextField as MaterialTextField,
  TextFieldProps,
} from '@material-ui/core';
import styled from 'styled-components';

import { onSurfaceHigh, primary1 } from 'styles/colors';

const MaterialTextFieldStyled = styled(MaterialTextField)`
  .MuiFormLabel-root {
    color: ${onSurfaceHigh};

    &.Mui-focused {
      color: ${onSurfaceHigh};
    }
  }
  .MuiInputBase-input {
    color: ${onSurfaceHigh};
  }
  .MuiOutlinedInput-root {
    border-radius: 0.5rem;

    fieldset {
      border-color: ${onSurfaceHigh};
    }
    &:hover fieldset {
      border-color: ${onSurfaceHigh};
    }
    &.Mui-focused fieldset {
      border-color: ${primary1};
    }
  }
`;

export const TextField: FC<TextFieldProps> = props => (
  <MaterialTextFieldStyled variant="outlined" {...props} />
);
