import React from 'react';
import styled from 'styled-components';

import { Regular } from '..';
import { background, primary } from '../../styles/colors';

interface IProgressBar {
  step: number;
  totalSteps: number;
}

export const ProgressBar: React.FC<IProgressBar> = ({ step, totalSteps }) => {
  const progress = (step * 100) / totalSteps;

  return (
    <Spacing>
      <Regular
        textAlign="center"
        margin="0 0 1rem 0"
      >{`${step} of ${totalSteps}`}</Regular>
      <ProgressBorder>
        <Progress width={progress.toFixed(2)} />
      </ProgressBorder>
    </Spacing>
  );
};

const Spacing = styled.div`
  padding: 2rem 1rem 1.5rem 1rem;
`;

const ProgressBorder = styled.div`
  display: block;
  max-width: 450px;
  height: 8px;
  margin: auto;
  background: ${background};
`;

const Progress = styled.div<{ width: string }>`
  width: ${props => props.width || '0'}%;
  height: 8px;
  background: ${primary};
  transition: width 0.2s;
`;
